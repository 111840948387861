<template>
  <v-data-table
    id="treatment-matrix"
    ref="treatmentMatrix"
    :headers="displayedHeaders"
    :items="displayedOrganisms"
    :loading="!source"
    :group-by="groupBy"
    hide-default-footer
    mobile-breakpoint="0"
    disable-pagination
    fixed-header
    dense
    style="width:100%"
  >
    <template
      v-if="formulary.id && formulary.id != 1"
      #top
    >
      <v-alert
        class="text-caption text-no-wrap ma-0"
        color="info"
        text
        dense
      >
        <template #prepend>
          <v-icon
            small
            class="mr-1"
            color="info"
          >
            fa-light fa-prescription-bottle-pill
          </v-icon>
        </template>
        <b>{{ formulary.name }}</b> formulary in use
      </v-alert>
    </template>
    <template
      v-if="groupBy"
      #group.header="{ group, headers, isOpen, toggle }"
    >
      <td
        :colspan="headers.length"
        class="text-start"
      >
        <v-btn
          icon
          :x-small="!$vuetify.breakpoint.mobile"
          class="ml-n3 mr-1"
          @click="toggle"
        >
          <v-icon
            :x-small="!$vuetify.breakpoint.mobile"
            v-text="isOpen ? 'mdi-minus' : 'mdi-plus'"
          />
        </v-btn>
        <span class="text-overline">{{ group ? group : 'Uncategorized' }}</span>
      </td>
    </template>
    <template #item.organism="{ item }">
      <span
        class="text-caption text-no-wrap"
        :class="{ 'font-weight-bold': pathogens && pathogens.includes(item.name) }"
      >
        {{ formatName(item.name) }}
      </span>
    </template>
    <template
      v-for="(drug) in headers.slice(1)"
      #[`header.${drug.value}`]="{ header }"
    >
      <v-tooltip
        :key="`drug-${drug.id}`"
        :disabled="clickableDrugs"
        color="white"
        :max-width="450"
        top
      >
        <template #activator="{ on }">
          <div
            :id="`column-${drug.id}`"
            v-on="on"
            @click="$emit('drug-click', drug)"
          >
            <div>
              {{ drug.short_name ? drug.short_name : (drug.name[$i18n.locale] || drug.name.en).replace('/', '/ ') }}
              <!-- {{ $vuetify.breakpoint.mobile && drug.short_name ? drug.short_name : drug.name.replace('/', '/ ') }} -->
            </div>
            <arkscore-gauge
              :key="`arkscore-gauge-${header.score_adverse_reaction}`"
              :value="drug.score_adverse_reaction"
              :size="36"
            />
            <v-icon
              v-if="header.no_oral_option"
              :key="drug.id"
              color="teal"
              class="px-1 ml-1 mr-n7"
              small
            >
              fa-light fa-syringe
            </v-icon>
            <!-- <v-divider />
            <v-icon
              small
              class="my-1"
            >
              fal fa-dna
            </v-icon> -->
          </div>
        </template>
        <template #default>
          <span style="color:#000">
            <div class="text-h6">
              {{ drug.name[$i18n.locale] || drug.name.en }}
            </div>
            <drug-info
              :drug="drug"
              flat
            />
          </span>
        </template>
      </v-tooltip>
    </template>
    <template #header.organism>
      <v-row
        justify="space-between"
        no-gutters
        class="px-2"
      >
        <v-col
          v-if="$auth.check()"
          cols="4"
        >
          <v-btn
            icon
            :small="!$vuetify.breakpoint.mobile"
            @click="toggleMatrix"
          >
            <v-icon v-text="collapsed ? 'mdi-menu-up' : 'mdi-menu-down'" />
          </v-btn>
        </v-col>
        <v-col
          v-show="!collapsed"
          cols="4"
        >
          <v-tooltip
            color="grey lighten-5"
            content-class="legend-tooltip"
            :open-on-hover="false"
            :open-on-focus="false"
            right
          >
            <template #activator="{ on }">
              <v-btn
                :key="groupBy"
                icon
                :small="!$vuetify.breakpoint.mobile"
                v-on="on"
              >
                <v-icon
                  :size="16"
                  v-text="'mdi-key'"
                />
              </v-btn>
            </template>
            <v-subheader light>
              Icon Key
            </v-subheader>
            <v-list-item
              v-for="item, key in legend"
              :key="item.icon"
              dense
            >
              <v-list-item-action class="mr-2 ml-n2">
                <v-icon
                  :color="item.color"
                  small
                >
                  fa-fw {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t(`meds_matrix.${key}`) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="$auth.check()"
          v-show="!collapsed"
          class="text-right"
          cols="4"
        >
          <v-btn
            :key="groupBy"
            icon
            :small="!$vuetify.breakpoint.mobile"
            @click="() => { groupBy = !groupBy ? 'category.name' : null}"
          >
            <v-icon
              :size="16"
              v-text="groupBy ? 'mdi-format-vertical-align-center' : 'mdi-arrow-expand-vertical'"
            />
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template
      v-for="drug in headers.slice(1)"
      #[`item.${drug.value}`]="{ item }"
    >
      <td
        :key="`drug_${drug.id}-${loading}`"
        class="text-center v-data-table__divider text-no-wrap"
        :class="{ 'active': selectedDrug && selectedDrug.id == drug.id }"
      >
        <v-icon
          v-if="loading"
          key="loading-spinner"
          small
        >
          fad fa-spinner fa-spin-pulse
        </v-icon>
        <treatment-table-icon
          v-for="icon, index in icons(item, drug)"
          v-else
          :key="`${icon}-icon`"
          :index="index"
          :icon-key="icon"
          :color="iconColor(icon, icons(item, drug))"
          :text="$t(`meds_matrix.${icon}`)"
          :icon="legend[icon]?.icon"
          :organism="item"
          :drug="drug"
          :resistance-names="resistance_names"
          :drug-resistance="drugResistance"
          :allergies="allergies"
          :pregnant="pregnant"
          :pediatric="pediatric"
          :nih-data="nih_data"
        />
      </td>
    </template>
    <template #loading>
      <v-icon small>
        fad fa-spinner fa-spin-pulse
      </v-icon>
      <span class="text-overline">
        Calculating MedsMatrix&hellip;
      </span>
    </template>
    <template #no-data>
      <v-icon small>
        fal fa-grid
      </v-icon>
      <span class="text-overline">
        MedsMatrix
      </span>
    </template>
  </v-data-table>
</template>
<script>
export default {
  components: {
    TreatmentTableIcon: () => import('@/components/interpretation/TreatmentTableIcon.vue'),
    ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
    DrugInfo: () => import('@/components/drug/DrugInfo.vue'),
  },
  props: {
    source: {
      type: String,
      default: null,
    },
    organisms: {
      type: Array,
      default: () => ([]),
    },
    pathogens: {
      type: Array,
      default: null,
    },
    resistance: {
      type: Array,
      default: () => ([]),
    },
    allergies: {
      type: Array,
      default: () => ([]),
    },
    pediatric: {
      type: Boolean,
      default: false,
    },
    pregnant: {
      type: Boolean,
      default: false,
    },
    drugResistance: {
      type: Array,
      default: null,
    },
    clickableDrugs: {
      type: Boolean,
      default: false,
    },
    selectedDrug: {
      type: Object,
      default: () => ({}),
    },
    formulary: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
  },
  data: () => ({
    collapsed: false,
    groupBy: null,
    nih_data: {},
  }),
  computed: {
    resistance_names () {
      return this.resistance.map(r => r.name.en)
    },
    priority_organisms () {
      return [...this.organisms].sort((a, b) => (b.priority > a.priority) ? 1 : -1)
    },
    effective_drugs() {
      return this.priority_organisms.flatMap(x => x.effective_drugs).filter(x => !!x)
    },
    drug_allergies() {
      return this.allergies.filter(x => x.group).flatMap(x => x.group.drug_classes).map(x => x.id)
    },
    drug_sensitivities() {
      return this.organisms.filter(x => x.result.sensitivities && x.result.sensitivities.length).map(x => ({
        organism: x.name,
        sensitivities: x.result.sensitivities.map(x => ({
          drug: x.name.en,
          values: x.values,
        })),
      }))
    },
    headers() {
      if (this.organisms.length) {
        var drugs = this.effective_drugs
          // Make the array only contain unique values
          .filter((value, index, self) => self.findIndex(d => d.id === value.id) === index)
          // Remove drugs that are not compatible with the source of the infection
          .filter(x => !x.ineffective_sources?.includes(this.source))
          .map(drug => ({
            ...drug,
            ...{
              value: drug.name.en,
              resistanceDetected: drug.resistance.map(dr => dr.name.en).filter(value => this.resistance_names.includes(value)).length,
              align: 'center',
              sortable: false,
              divider: true,
              count: this.organisms.filter(x => x.effective_drugs && x.effective_drugs.some(d => d.id == drug.id)),
              width: 101,
              rank: this.drugRank(drug),
              class: {
                clickable: this.clickableDrugs,
                active: this.selectedDrug && this.selectedDrug.id == drug.id,
              },
            },
          }))
          .sort((a,b) => (a.count == b.count ? a.rank > b.rank : a.count > b.count) ? -1 : 1)
        return [
          {
            text: '',
            value: 'organism',
            sortable: false,
            divider: true,
            fixed: true,
          },
        ].concat(drugs)
      } else {
        return []
      }
    },
    displayedHeaders() {
      return !this.collapsed ? this.headers : [{value:'organism', sortable:false}]
    },
    displayedOrganisms() {
      let rows = [...this.organisms].sort((a, b) => (a.name > b.name) ? 1 : -1)
      // rows = [{
      //   name: 'Susceptibility',
      // }].concat(rows)
      return !this.collapsed ? rows : []
    },
    legend() {
      let legend = {
        susceptible: {
          icon: 'fa-solid fa-square-s',
          color: 'success darken',
        },
        susceptible_dose_dependant: {
          icon: 'fa-solid fa-standard-definition',
          color: 'success darken',
        },
        intermediate: {
          icon: 'far fa-square-i',
          color: 'grey darken-1',
        },
        activity: {
          icon: 'fa-solid fa-square-check',
          color: 'success',
        },
        activity_possible: {
          icon: 'fa-solid fa-square-question',
          color: '#0CB577',
        },
        variable: {
          icon: 'far fa-square-check',
          color: 'teal lighten-1',
        },
        variable_possible: {
          icon: 'far fa-square-question',
          color: 'teal lighten-1',
        },
        allergies: {
          icon: 'fa-solid fa-allergies',
          color: 'error lighten-1',
        },
        pregnant: {
          icon: 'fa-solid fa-person-pregnant',
          color: 'pink darken-1',
        },
        pediatric: {
          icon: 'fa-solid fa-child',
          color: 'warning darken-1',
        },
        resistance: {
          icon: 'fa-solid fa-shield-virus',
          color: 'error lighten-1',
        },
        resistant: {
          icon: 'fas fa-square-r',
          color: 'error',
        },
        unknown: {
          icon: 'far fa-minus',
          color: 'grey lighten-1',
        },
        iv: {
          icon: 'fa-light fa-syringe',
          color: 'teal',
        },
      }
      if (!this.drug_sensitivities.length) {
        delete legend.susceptible;
        delete legend.susceptible_dose_dependant;
        delete legend.intermediate;
        delete legend.resistant;
        delete legend.activity_possible;
        delete legend.variable_possible;
      // } else {
      //   delete legend.activity;
      //   delete legend.variable;
      }
      if (!this.resistance_names.length) {
        delete legend.resistance;
      }
      if (!this.drug_allergies.length) {
        delete legend.allergies;
      }
      if (!this.pregnant) {
        delete legend.pregnant;
      }
      if (!this.pediatric) {
        delete legend.pediatric;
      }
      return legend
    },
  },
  watch: {
    groupBy () {
      if (this.$refs.treatmentMatrix && this.$refs.treatmentMatrix.$el !== undefined) {
        setTimeout(() => this.$emit('resize', this.$refs.treatmentMatrix.$el.clientHeight), 100);
      }
    },
    displayedHeaders () {
      if (this.$refs.treatmentMatrix && this.$refs.treatmentMatrix.$el !== undefined) {
        setTimeout(() => this.$emit('resize', this.$refs.treatmentMatrix.$el.clientHeight), 100);
      }
    },
    'selectedDrug.id' (id) {
      if (id) {
        const column = document.getElementById(`column-${id}`);
        column.scrollIntoView({
          behavior: "smooth",
          inline: "center",
          block: "nearest",
        });
      }
    },
  },
  created () {
    if (this.resistance.length && !Object.keys(this.nih_data).length) {
      this.displayedOrganisms.forEach(organism => {
        this.$set(this.nih_data, organism.name, {})
        this.resistance.forEach(resistance => {
          this.axios.get('data/frequency', { params: {
            organism: organism.name,
            resistance: resistance.name.en,
          }})
            .then((response) => {
              this.$set(this.nih_data[organism.name], resistance.name.en, response.data)
            })
        })
      })
    }
  },
  mounted () {
    if (this.clickableDrugs && !this.selectedDrug.id && this.headers[1]) {
      let drug = this.headers[1]
      this.$emit('drug-click', drug)
    }
  },
  methods: {
    formatName(name) {
      if (this.$vuetify.breakpoint.mobile) {
        var names = name.split(/\s+/);
        names[0] = names[0]?.substr(0, 1) + ".";
        return names.join(' ');
      } else {
        return this.$options.filters.truncate(name, 30)
      }
    },
    drugRank(drug) {
      return this.effective_drugs.filter(d => d.id == drug.id).map(x => 20 - x.pivot.order).reduce((a, b) => a + b)
    },
    toggleMatrix() {
      this.collapsed = !this.collapsed
    },
    // legend[icon] {
    //   return this.legend.find(x => x.icon == icon)
    // },
    icons(organism, drug) {
      var icons = []
      if (!organism.effective_drugs.some(d => d.id == drug.id)) {
        icons.push('unknown')
        return icons
      }
      if (this.drug_sensitivities.some(x => x.organism == organism.name && x.sensitivities.some(s => s.drug == drug.name.en))) {
        let result = this.drug_sensitivities.find(x => x.organism == organism.name).sensitivities.find(s => s.drug == drug.name.en).values.result
        if (result == 'S') {
          icons.push('susceptible')
        }
        if (result == 'I') {
          icons.push('intermediate')
        }
        if (result == 'R') {
          icons.push('resistant')
        }
        if (result == 'SDD') {
          icons.push('susceptible_dose_dependant')
        }
      }
      if (organism.relevant_resistances.map(rr => rr.name.en).filter(value => this.resistance_names.includes(value)).filter(value => drug.resistance.map(dr => dr.name.en).includes(value)).length) {
        icons.push('resistance')
      }
      if (drug.no_pediatric_use && this.pediatric) {
        icons.push('pediatric')
      }
      if ((drug.category >= 'C' || drug.category == '?') && this.pregnant) {
        icons.push('pregnant')
      }
      if (this.drug_allergies.includes(drug.class_id)) {
        icons.push('allergies')
      }
      if (!icons.length) {
        if (organism.effective_drugs.some(d => d.id == drug.id) && organism.effective_drugs.find(d => d.id == drug.id).pivot.variable) {
          if (this.drug_sensitivities && this.drug_sensitivities.some(x => x.organism == organism.name)) {
            icons.push('variable_possible')
          } else {
            icons.push('variable')
          }
        } else {
          if (this.drug_sensitivities && this.drug_sensitivities.some(x => x.organism == organism.name)) {
            icons.push('activity_possible')
          } else {
            icons.push('activity')
          }
        }
      }
      if (!icons.length) {
        icons.push('unknown')
      }
      return icons
    },
    iconColor(icon, icons) {
      if (icons.includes('resistance') && ['susceptible', 'intermediate'].includes(icon)) {
        return this.legend['resistance'].color
      } else if (icons.includes('pregnant') && ['susceptible', 'intermediate'].includes(icon)) {
        return this.legend['pregnant'].color
      } else if (icons.includes('allergies') && ['susceptible', 'intermediate'].includes(icon)) {
        return this.legend['allergies'].color
      }
      return this.legend[icon]?.color
    },
  },
}
</script>
<style lang="scss">
  #treatment-matrix {
    table > tbody > tr > td:nth-child(1),
    table > thead > tr > th:nth-child(1) {
      position: sticky !important;
      position: -webkit-sticky !important;
      left: 0;
      z-index: 5;
      background: white;
    }
    table > thead > tr > th:nth-child(1) {
      z-index: 5;
    }
    table > thead > tr > th:not(:first-child) {
      vertical-align: bottom;
      padding-top: 3px;
      padding-left: 8px;
      padding-right: 8px;
    }
    table > thead > tr > th {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    table > thead > tr > th.active,
    table > tbody > tr > td.active {
      background: rgba(79, 27, 154, 0.06);
    }
    .v-row-group__header {
      td {
        background-color: #fafafa !important;
      }
      .text-overline {
        font-size: 0.8em !important;
      }
    }
    .v-expansion-panel-content__wrap {
        padding: 0;
    }
  }
  .legend-tooltip {
      opacity: 0.99 !important;
  }
  .clickable {
    cursor: pointer;
  }

</style>
